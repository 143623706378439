//Importacion de componentes externos.
import React, { Fragment, useState, useEffect } from "react";
import {
  Redirect,
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { ModalProvider } from "react-simple-hook-modal";

//Importacion de componentes internos.
import Header from "./components/Header/Header";
import Index from "./components/Index/Index";
import Mercado from "./components/Mercado/Mercado";
import Mayoristas from "./components/Mercado/Mayoristas";
import Pedido from "./components/Pedido/Pedido";
import PaymentConfirmation from "./components/ConfirmacionPago/index";
import PaymentRejected from "./components/ConfirmacionPago/Rechazado";
import PaymentPending from "./components/ConfirmacionPago/Pendiente";
import ModalGeneral from "./components/Modal/ModalGeneral";
import InicioSesion from "./components/Usuario/InicioSesion";
import Ayuda from "./components/Ayuda/Ayuda";
import Loader from "react-loader-spinner";

import Landing from "./components/Landing/Landing";
import Indicadores from "./components/Admin/Indicadores/Indicadores";
import DetalleMeta from "./components/Admin/Indicadores/DetalleMeta";
import Detalle from "./components/Mercado/Detalle";
import CrudIndicadores from "./components/Admin/CrudIndicadores/CrudInicadores";
import Unidades from "./components/Admin/Unidades/Unidades";
import MenuPrincipal from "./components/Admin/Index/MenuPrincipal";
import MenuUnidades from "./components/Admin/Index/MenuUnidades";
import Dashboard from "./components/Admin/Dashboard/Dashboard";
import Whatsapp from "./components/Admin/Whatsapp/Whatsapp";

import RegistroTercero from "./components/Concurso/registroConcurso";

/** Importacion de servicios */
import apiFacebook from "./services/apiFacebook";

//Importaciones de css
import "./components/Index/static/css/Index.css";
import "./static/css/General.css";
import TagManager from "react-gtm-module";
import ReactPixel from "react-facebook-pixel";
import Rotacion from "./components/Admin/Rotacion/Rotacion";
import RotacionIndex from "./components/Admin/Rotacion/RotacionIndex";
import RotacionSeller from "./components/Admin/Rotacion/RotacionSeller";
import NewIndex from "./components/Index/NewIndex";
import Imagenes from "./components/Admin/Imagenes/Imagenes";
import LoginVendedor from "./components/Vendedor/LoginVendedor";
import Promocion from "./components/Promos/Promocion"
import Descuentos from "./components/Admin/Descuentos/Descuentos";
import { CategoriasProvider } from "./providers/CategoriasProviders";
import { GlobalStatesProvider } from './providers/GlobalStatesProviders';
import { actualizarEventId } from "./global/functions";

const tagManagerArgs = {
  //GTM-5RGW82S
  gtmId: "GTM-5RGW82S",
};

TagManager.initialize(tagManagerArgs);
TagManager.dataLayer({
  dataLayer: {
    event: "pageview",
    path: "/",
  },
});
// ReactPixel.init("1057432718119761");
//ReactPixel.pageView();
//ReactPixel.track("track", "ViewContent");
function App() {
  let DEBUG = false;

  //State para peticiones al servidor, determinado por el estado del DEBUG. 192.168.2.109 "http://181.55.127.197:5000"
  const [apiUrlState, modificarApiUrlState] = useState(
    DEBUG ? "http://localhost:4000" : "https://api.basiccouture.com.co"
  );

  //State para el uso de recursos de imagenes segun el estado del DEBUG.
  const [recursosUrlState, modificarRecursosUrlState] = useState(
    DEBUG ? "https://hosting.basic.dekada.co/BasicCouture" : "https://hosting.basic.dekada.co/BasicCouture"
  );

  /** Envia el evento de visita a la pagina web a facebook */
  const enviarEventoFacebook = async () => {
    const event_id = actualizarEventId();
    const userIP = await apiFacebook.get();
    apiFacebook.post(`${apiUrlState}/Api/Facebook/SendEvent`, {
      event_id: `pv-${event_id}`,
      event_name: "PageView",
      event_source_url: window.location.href,
      client_user_agent: navigator.userAgent,
      client_ip_address: userIP || "0.0.0.0"
    });

    /** Activar evento mediante pixel -> Pendiente */
    // fbq("track", "pageView", {}, { 
    //   eventID: id 
    // });

    // ReactPixel.track("pageView", {}, { 
    //   eventID: id 
    // });
  }

  //State que contiene la lista de productos actuales del cliente.
  const [carrito, modificarCarritoState] = useState({
    Accion: "Recuperar",
    Carrito: [],
  });

  //State para definicion de los colores del header y ocultar y mostrar elementos.
  const [headerState, modificarHeaderState] = useState({
    srcLogo: recursosUrlState + "/img/Email/45441614379749154.png",
    srcBolsa: recursosUrlState + "/img/Index/web/home/carrito.png",
    srcFlecha:
      recursosUrlState + "/img/Index/web/botones/flecha-derecha-negro.png",
    displayBuscar: "in-line",
    displayMenu: "Index",
    color: "negro",
    Seccion: "",
    Cliente: "",
    BotonCatalogo: false,
  });

  const [AdminHeaderState, setAdminHeaderState] = useState({
    second: "SecondHeader",
    principal: "centerHeader",
  });

  const [administradorState, modificarAdministradorState] = useState({
    Recuperar: true,
    NumeroDocumento: "",
    Nombres: "",
    Apellidos: "",
    Tipo: "",
  });

  const [metodoFact, setMetodoFact] = useState({
    Facturacion: 0,
    Remision: 100
  });

  /** Seleccion de bodega para catalogos */
  let parsedBodegaC
  const localStorageBodegaC = localStorage.getItem("BodegaCatalogos");
  if(!localStorageBodegaC) {
    parsedBodegaC = "03"
  } else {
    parsedBodegaC = localStorageBodegaC;
  }
  const [BodegaCatalogos, setBodegaCatalogos] = useState(parsedBodegaC);
  
  //** Estados para manejo de vendedores */
  let parsedBodega
  const localStorageBodega = localStorage.getItem("Bodega");
  if(!localStorageBodega) {
    parsedBodega = "03"
  } else {
    parsedBodega = localStorageBodega;
  }
  const [Bodega, setBodega] = useState(parsedBodega);

  let parsedVendedor;
  const localStorageVendedor = localStorage.getItem("Vendedor");
  if(!localStorageVendedor) {
    // localStorage.setItem('Vendedor', JSON.stringify([]));
    parsedVendedor = null
  } else {
    parsedVendedor = JSON.parse(localStorageVendedor);
    localStorage.setItem("TipoCliente", "Mayoristas")
  }
  const [vendedor, modificarVendedorState] = useState(parsedVendedor);
  //** END Estados para manejo de vendedores */

  //State para el despliegue del spinner.
  const [spinnerState, modificarSpinnerState] = useState({
    Desplegar: false,
    Texto: "Cargando productos...",
  });

  //State para estados de pagos en pedidos y la pagina.
  const [pedidoState, modificarPedidoState] = useState({
    Pagina: 1,
    Efecty: false,
    EfectyImg: recursosUrlState + "/img/Pedido/efecty-negro.png",
    Baloto: false,
    BalotoImg: recursosUrlState + "/img/Pedido/baloto-negro.png",
    PSE: false,
    PSEImg: recursosUrlState + "/img/Pedido/pse-negro.png",
    Credito: false,
    CreditoImg: recursosUrlState + "/img/Pedido/credito-negro.png",
    Contraentrega: false,
    ContraentregaImg: recursosUrlState + "/img/Pedido/contraentrega.png",
    Consignacion: false,
    ConsignacionImg: recursosUrlState + "/img/Pedido/bancolombia-negro.png",
    SuperGiros: false,
    SuperGirosImg: recursosUrlState + "/img/Pedido/supergiros-negro.png",
    Epayco: false,
    EpaycoImg: recursosUrlState + "/img/Pedido/epayco-negro.png",
    Addi: false,
    AddiImg: recursosUrlState + "img/Pedido/ADDI_logo.png",
  });

  //State para despliegue del modal de error e informacion.
  const [errorState, modificarErrorState] = useState({
    Error: false,
    Mensaje: "",
  });

  //State para los radioButtons del index, en el cambio de imagen.
  const [radioButtons, modificarRadioButtons] = useState({
    radio1: recursosUrlState + "/img/Index/web/botones/radioSeleccion.png",
    radio2: recursosUrlState + "/img/Index/web/botones/radioSinSeleccionar.png",
    radio3: recursosUrlState + "/img/Index/web/botones/radioSinSeleccionar.png",
    radio4: recursosUrlState + "/img/Index/web/botones/radioSinSeleccionar.png",
    radio5: recursosUrlState + "/img/Index/web/botones/radioSinSeleccionar.png",
  });

  //State que contiene estados de busqueda de productos y tambien la lista de productos actuales.
  const [productosState, modificarProductosState] = useState([
    { CambioSeccion: true, Busqueda: false, irInicio: false },
    [],
  ]);
  //State que contiene los estados del header para modificar su estilo en la vista de landing.
  const [landingState, modificarlandingState] = useState({
    Display: "",
    Posicion: "1%",
    positionlogo: "",
    transform: "",
  });

  //State para la categoria y genero, estados de cambio de filtro, categoria y posicion de filtro.
  const [filtrosState, modificarFiltrosState] = useState({
    Genero: "607ed653521b1c0910a581b8",
    Categoria: "",
    CambioFiltro: false,
    CambioCategoria: false,
    filtroResumido: false,
    posicionFiltros: "-100%",
    width: "0px",
  });

  //State de estados de categorias de hombre.
  const [categoriasState, modificarCategoriasState] = useState({
    Hombre: null,
    Mujer: null,
    Niño: null,
  });
  const [subCategoriasState, modificarSubCategoriasState] = useState("");

  const [LandingHeader, SetLandingHeader] = useState({ Estado: false });

  const [ViewDetalle, SetViewDetalle] = useState({ Estado: false });

  const [tallasState, modificarTallasState] = useState([]);

  const [coloresState, modificarColoresState] = useState([]);

  const [marcasState, modificarMarcasState] = useState([]);

  const [medidasState, modificarMedidasState] = useState([]);

  const [refNoCatalogoState, modificarRefNoCatalogoState] = useState([]);

  // Estabelece el check de la lista de productos en catálogo
  const [checkedProductos, setCheckedProductos] = useState(true);

  const [ayudaState, modificarAyudaState] = useState({
    Producto: false,
    Envio: false,
    Cambios: false,
  });

  const actualizarSessionStorage = (nombreItem, item) => {
    //Obtengo todos los items de la sessionStorage actual, para no perder ningun dato.
    const clienteSession = localStorage.Cliente;
    const tipoClienteSession = localStorage.TipoCliente;
    const carritoSession = localStorage.Carrito;
    const administradorSession = localStorage.Administrador;

    //Limpiamos la localStorage actual.
    //localStorage.clear();
    localStorage.removeItem("Cliente", "TipoCliente", "Carrito", "Administrador");
    //Y añadimos los item a la localStorage, primeramente el item que entra por parametro y despues cada uno de los otros items verificando si no es el que entro por parametro.
    localStorage.setItem(nombreItem, item);
    if (nombreItem !== "Cliente")
      localStorage.setItem("Cliente", clienteSession);
    if (nombreItem !== "TipoCliente")
      localStorage.setItem("TipoCliente", tipoClienteSession);
    if (nombreItem !== "Carrito")
      localStorage.setItem("Carrito", carritoSession);
    if (nombreItem !== "Administrador")
      localStorage.setItem("Administrador", administradorSession);
  };

  useEffect(() => {
    enviarEventoFacebook();
   }, [])

  return (
    <Fragment>
    <GlobalStatesProvider>
      {spinnerState["Desplegar"] ? (
        <div className="Spinner">
          <div className="posicionSpinner">
            <Loader type="Bars" color="#fff" height={100} width={100} />
          </div>
          <div>
            <p
              className="posicionTextoSpinner FuenteBebas text-center"
              style={{ color: "white", fontSize: "1.4em", width: "50%" }}
            >
              {spinnerState["Texto"]}
            </p>
          </div>
        </div>
      ) : (
        <div></div>
      )}

      <Router>
      <Header
        AdminHeaderState={AdminHeaderState}
        actualizarSessionStorage={actualizarSessionStorage}
        administradorState={administradorState}
        apiUrlState={apiUrlState}
        carrito={carrito}
        categoriasState={categoriasState}
        subCategoriasState={subCategoriasState}
        modificarSubCategoriasState={modificarSubCategoriasState}
        // clienteState={clienteState}
        coloresState={coloresState}
        errorState={errorState}
        filtrosState={filtrosState}
        headerState={headerState}
        marcasState={marcasState}
        medidasState={medidasState}
        modificarAdministradorState={modificarAdministradorState}
        modificarApiUrlState={modificarApiUrlState}
        modificarCarritoState={modificarCarritoState}
        modificarCategoriasState={modificarCategoriasState}
        // modificarClienteState={modificarClienteState}
        modificarColoresState={modificarColoresState}
        modificarErrorState={modificarErrorState}
        modificarFiltrosState={modificarFiltrosState}
        modificarHeaderState={modificarHeaderState}
        modificarMarcasState={modificarMarcasState}
        modificarMedidasState={modificarMedidasState}
        modificarProductosState={modificarProductosState}
        modificarRecursosUrlState={modificarRecursosUrlState}
        modificarSpinnerState={modificarSpinnerState}
        modificarTallasState={modificarTallasState}
        productosState={productosState}
        recursosUrlState={recursosUrlState}
        spinnerState={spinnerState}
        tallasState={tallasState}
        landingState={landingState}
        LandingHeader={LandingHeader}
        ViewDetalle={ViewDetalle}
        refNoCatalogoState={refNoCatalogoState}
        modificarRefNoCatalogoState={modificarRefNoCatalogoState}
        vendedor={vendedor}
        modificarVendedorState={modificarVendedorState}
        Bodega={Bodega}
        setBodega={setBodega}
        BodegaCatalogos={BodegaCatalogos}
        setBodegaCatalogos={setBodegaCatalogos}
        checkedProductos={checkedProductos}
        setCheckedProductos={setCheckedProductos}
      />
      <ModalProvider>
        <ModalGeneral
          // clienteState={clienteState}
          // modificarClienteState={modificarClienteState}
          actualizarSessionStorage={actualizarSessionStorage}
          carrito={carrito}
          modificarCarritoState={modificarCarritoState}
          errorState={errorState}
          modificarErrorState={modificarErrorState}
          apiUrlState={apiUrlState}
          recursosUrlState={recursosUrlState}
          modificarRecursosUrlState={modificarRecursosUrlState}
          headerState={headerState}
          modificarHeaderState={modificarHeaderState}
          spinnerState={spinnerState}
          modificarSpinnerState={modificarSpinnerState}
          vendedor={vendedor}
          modificarVendedorState={modificarVendedorState}
          Bodega={Bodega}
          setBodega={setBodega}
          metodoFact={metodoFact}
          setMetodoFact={setMetodoFact}
        />
      </ModalProvider>

      {/* <Router> */}
        <Switch>
          <Redirect from="/Mayoristas/Colombia" to="/" />
          <Route
            exact
            path="/"
            render={() => (
              <>
                {/* <Promocion/> */}
                <CategoriasProvider>
                  <NewIndex
                    headerState={headerState}
                    modificarHeaderState={modificarHeaderState}
                    categoriasState={categoriasState}
                    modificarCategoriasState={modificarCategoriasState}
                    marcasState={marcasState}
                    modificarMarcasState={modificarMarcasState}
                    productosState={productosState}
                    modificarProductosState={modificarProductosState}
                    spinnerState={spinnerState}
                    modificarSpinnerState={modificarSpinnerState}
                    errorState={errorState}
                    modificarErrorState={modificarErrorState}
                    apiUrlState={apiUrlState}
                    BodegaCatalogos={BodegaCatalogos}
                    setBodegaCatalogos={setBodegaCatalogos}
                  />
                </CategoriasProvider>
              </>

              /*<Mercado
                apiUrlState={apiUrlState}
                carrito={carrito}
                CatalogoState={false}
                categoriasState={categoriasState}
                clienteState={clienteState}
                coloresState={coloresState}
                errorState={errorState}
                filtrosState={filtrosState}
                headerState={headerState}
                marcasState={marcasState}
                modificarApiUrlState={modificarApiUrlState}
                modificarCarritoState={modificarCarritoState}
                modificarCategoriasState={modificarCategoriasState}
                modificarClienteState={modificarClienteState}
                modificarColoresState={modificarColoresState}
                modificarErrorState={modificarErrorState}
                modificarFiltrosState={modificarFiltrosState}
                modificarHeaderState={modificarHeaderState}
                modificarMarcasState={modificarMarcasState}
                modificarProductosState={modificarProductosState}
                modificarRecursosUrlState={modificarRecursosUrlState}
                modificarSpinnerState={modificarSpinnerState}
                modificarTallasState={modificarTallasState}
                productosState={productosState}
                recursosUrlState={recursosUrlState}
                spinnerState={spinnerState}
                tallasState={tallasState}
                actualizarSessionStorage={actualizarSessionStorage}
                rutaDinamica={false}
                ViewDetalle={ViewDetalle}
              />*/
            )}
          />
          <Route
            exact
            path="/Mercadito"
            render={() => (
              <Index
                headerState={headerState}
                modificarHeaderState={modificarHeaderState}
                radioButtons={radioButtons}
                modificarRadioButtons={modificarRadioButtons}
                errorState={errorState}
                modificarErrorState={modificarErrorState}
                spinnerState={spinnerState}
                modificarSpinnerState={modificarSpinnerState}
                recursosUrlState={recursosUrlState}
                modificarRecursosUrlState={modificarRecursosUrlState}
                // clienteState={clienteState}
                // modificarClienteState={modificarClienteState}
                actualizarSessionStorage={actualizarSessionStorage}
                ViewDetalle={ViewDetalle}
              />
            )}
          />
          <Route
            exact
            path="/Mercado"
            render={() => (
              <CategoriasProvider>
                <Mercado
                  apiUrlState={apiUrlState}
                  carrito={carrito}
                  CatalogoState={false}
                  categoriasState={categoriasState}
                  // clienteState={clienteState}
                  coloresState={coloresState}
                  errorState={errorState}
                  filtrosState={filtrosState}
                  headerState={headerState}
                  marcasState={marcasState}
                  medidasState={medidasState}
                  modificarApiUrlState={modificarApiUrlState}
                  modificarCarritoState={modificarCarritoState}
                  modificarCategoriasState={modificarCategoriasState}
                  // modificarClienteState={modificarClienteState}
                  modificarColoresState={modificarColoresState}
                  modificarErrorState={modificarErrorState}
                  modificarFiltrosState={modificarFiltrosState}
                  subCategoriasState={subCategoriasState}
                  modificarSubCategoriasState={modificarSubCategoriasState}
                  modificarHeaderState={modificarHeaderState}
                  modificarMarcasState={modificarMarcasState}
                  modificarMedidasState={modificarMedidasState}
                  modificarProductosState={modificarProductosState}
                  modificarRecursosUrlState={modificarRecursosUrlState}
                  modificarSpinnerState={modificarSpinnerState}
                  modificarTallasState={modificarTallasState}
                  productosState={productosState}
                  recursosUrlState={recursosUrlState}
                  spinnerState={spinnerState}
                  tallasState={tallasState}
                  actualizarSessionStorage={actualizarSessionStorage}
                  rutaDinamica={false}
                  ViewDetalle={ViewDetalle}
                  refNoCatalogoState={refNoCatalogoState}
                  modificarRefNoCatalogoState={modificarRefNoCatalogoState}
                  vendedor={vendedor}
                  modificarVendedorState={modificarVendedorState}
                  Bodega={Bodega}
                  setBodega={setBodega}
                  BodegaCatalogos={BodegaCatalogos}
                  setBodegaCatalogos={setBodegaCatalogos}
                  checkedProductos={checkedProductos}
                  setCheckedProductos={setCheckedProductos}
                />
              </CategoriasProvider>
            )}
          />
          <Route
            exact
            path="/Mercado/:Categoria/:Genero"
            render={() => (
              <CategoriasProvider>
                <Mercado
                  apiUrlState={apiUrlState}
                  carrito={carrito}
                  CatalogoState={false}
                  categoriasState={categoriasState}
                  // clienteState={clienteState}
                  coloresState={coloresState}
                  errorState={errorState}
                  filtrosState={filtrosState}
                  headerState={headerState}
                  marcasState={marcasState}
                  medidasState={medidasState}
                  modificarApiUrlState={modificarApiUrlState}
                  modificarCarritoState={modificarCarritoState}
                  modificarCategoriasState={modificarCategoriasState}
                  // modificarClienteState={modificarClienteState}
                  modificarColoresState={modificarColoresState}
                  modificarErrorState={modificarErrorState}
                  modificarFiltrosState={modificarFiltrosState}
                  subCategoriasState={subCategoriasState}
                  modificarSubCategoriasState={modificarSubCategoriasState}
                  modificarHeaderState={modificarHeaderState}
                  modificarMarcasState={modificarMarcasState}
                  modificarMedidasState={modificarMedidasState}
                  modificarProductosState={modificarProductosState}
                  modificarRecursosUrlState={modificarRecursosUrlState}
                  modificarSpinnerState={modificarSpinnerState}
                  modificarTallasState={modificarTallasState}
                  productosState={productosState}
                  recursosUrlState={recursosUrlState}
                  spinnerState={spinnerState}
                  tallasState={tallasState}
                  actualizarSessionStorage={actualizarSessionStorage}
                  rutaDinamica={true}
                  ViewDetalle={ViewDetalle}
                  refNoCatalogoState={refNoCatalogoState}
                  modificarRefNoCatalogoState={modificarRefNoCatalogoState}
                  vendedor={vendedor}
                  modificarVendedorState={modificarVendedorState}
                  Bodega={Bodega}
                  setBodega={setBodega}
                  BodegaCatalogos={BodegaCatalogos}
                  setBodegaCatalogos={setBodegaCatalogos}
                  checkedProductos={checkedProductos}
                  setCheckedProductos={setCheckedProductos}
                />
              </CategoriasProvider>
            )}
          />
          <Route
            exact
            path="/Mayoristas"
            render={() => (
              <Mayoristas
                actualizarSessionStorage={actualizarSessionStorage}
                apiUrlState={apiUrlState}
                carrito={carrito}
                CatalogoState={false}
                categoriasState={categoriasState}
                // clienteState={clienteState}
                coloresState={coloresState}
                errorState={errorState}
                filtrosState={filtrosState}
                headerState={headerState}
                marcasState={marcasState}
                modificarApiUrlState={modificarApiUrlState}
                modificarCarritoState={modificarCarritoState}
                modificarCategoriasState={modificarCategoriasState}
                // modificarClienteState={modificarClienteState}
                modificarColoresState={modificarColoresState}
                modificarErrorState={modificarErrorState}
                modificarFiltrosState={modificarFiltrosState}
                modificarHeaderState={modificarHeaderState}
                modificarMarcasState={modificarMarcasState}
                modificarProductosState={modificarProductosState}
                modificarRecursosUrlState={modificarRecursosUrlState}
                modificarSpinnerState={modificarSpinnerState}
                modificarTallasState={modificarTallasState}
                productosState={productosState}
                recursosUrlState={recursosUrlState}
                spinnerState={spinnerState}
                tallasState={tallasState}
                rutaDinamica={false}
                ViewDetalle={ViewDetalle}
                vendedor={vendedor}
                modificarVendedorState={modificarVendedorState}
              />
            )}
          />
          <Route
            exact
            path="/Mayoristas/:Categoria/:Genero"
            render={() => (
              <Mayoristas
                actualizarSessionStorage={actualizarSessionStorage}
                apiUrlState={apiUrlState}
                carrito={carrito}
                CatalogoState={false}
                categoriasState={categoriasState}
                // clienteState={clienteState}
                coloresState={coloresState}
                errorState={errorState}
                filtrosState={filtrosState}
                headerState={headerState}
                marcasState={marcasState}
                modificarApiUrlState={modificarApiUrlState}
                modificarCarritoState={modificarCarritoState}
                modificarCategoriasState={modificarCategoriasState}
                // modificarClienteState={modificarClienteState}
                modificarColoresState={modificarColoresState}
                modificarErrorState={modificarErrorState}
                modificarFiltrosState={modificarFiltrosState}
                modificarHeaderState={modificarHeaderState}
                modificarMarcasState={modificarMarcasState}
                modificarProductosState={modificarProductosState}
                modificarRecursosUrlState={modificarRecursosUrlState}
                modificarSpinnerState={modificarSpinnerState}
                modificarTallasState={modificarTallasState}
                productosState={productosState}
                recursosUrlState={recursosUrlState}
                spinnerState={spinnerState}
                tallasState={tallasState}
                rutaDinamica={true}
                ViewDetalle={ViewDetalle}
                vendedor={vendedor}
                modificarVendedorState={modificarVendedorState}
              />
            )}
          />
          <Route
            exact
            path="/Admin/GeneradorDeCatalogos"
            render={() => (
              <CategoriasProvider>
                <Mercado
                  actualizarSessionStorage={actualizarSessionStorage}
                  apiUrlState={apiUrlState}
                  carrito={carrito}
                  CatalogoState={true}
                  categoriasState={categoriasState}
                  // clienteState={clienteState}
                  coloresState={coloresState}
                  errorState={errorState}
                  filtrosState={filtrosState}
                  headerState={headerState}
                  marcasState={marcasState}
                  medidasState={medidasState}
                  modificarApiUrlState={modificarApiUrlState}
                  modificarCarritoState={modificarCarritoState}
                  modificarCategoriasState={modificarCategoriasState}
                  // modificarClienteState={modificarClienteState}
                  modificarColoresState={modificarColoresState}
                  modificarErrorState={modificarErrorState}
                  modificarFiltrosState={modificarFiltrosState}
                  modificarHeaderState={modificarHeaderState}
                  modificarMarcasState={modificarMarcasState}
                  modificarMedidasState={modificarMedidasState}
                  modificarProductosState={modificarProductosState}
                  modificarRecursosUrlState={modificarRecursosUrlState}
                  modificarSpinnerState={modificarSpinnerState}
                  modificarTallasState={modificarTallasState}
                  productosState={productosState}
                  recursosUrlState={recursosUrlState}
                  spinnerState={spinnerState}
                  tallasState={tallasState}
                  rutaDinamica={false}
                  ViewDetalle={ViewDetalle}
                  refNoCatalogoState={refNoCatalogoState}
                  modificarRefNoCatalogoState={modificarRefNoCatalogoState}
                  vendedor={vendedor}
                  modificarVendedorState={modificarVendedorState}
                  Bodega={Bodega}
                  setBodega={setBodega}
                  BodegaCatalogos={BodegaCatalogos}
                  setBodegaCatalogos={setBodegaCatalogos}
                  checkedProductos={checkedProductos}
                  setCheckedProductos={setCheckedProductos}
                />
              </CategoriasProvider>
            )}
          />
          <Route
            exact
            path="/Pedido"
            render={() => (
              <Pedido
                headerState={headerState}
                modificarHeaderState={modificarHeaderState}
                carrito={carrito}
                modificarCarritoState={modificarCarritoState}
                pedidoState={pedidoState}
                modificarPedidoState={modificarPedidoState}
                // clienteState={clienteState}
                // modificarClienteState={modificarClienteState}
                errorState={errorState}
                modificarErrorState={modificarErrorState}
                spinnerState={spinnerState}
                modificarSpinnerState={modificarSpinnerState}
                apiUrlState={apiUrlState}
                modificarApiUrlState={modificarApiUrlState}
                recursosUrlState={recursosUrlState}
                modificarRecursosUrlState={modificarRecursosUrlState}
                actualizarSessionStorage={actualizarSessionStorage}
                administradorState={administradorState}
                modificarAdministradorState={modificarAdministradorState}
                vendedor={vendedor}
                modificarVendedorState={modificarVendedorState}
                Bodega={Bodega}
                setBodega={setBodega}
                metodoFact={metodoFact}
                setMetodoFact={setMetodoFact}
              />
            )}
          />
          <Route
            exact
            path="/Pedido/Confirmacion"
            render={() => (
              <PaymentConfirmation
                headerState={headerState}
                modificarHeaderState={modificarHeaderState}
                carrito={carrito}
                modificarCarritoState={modificarCarritoState}
                pedidoState={pedidoState}
                modificarPedidoState={modificarPedidoState}
                errorState={errorState}
                modificarErrorState={modificarErrorState}
                spinnerState={spinnerState}
                modificarSpinnerState={modificarSpinnerState}
                apiUrlState={apiUrlState}
                modificarApiUrlState={modificarApiUrlState}
                recursosUrlState={recursosUrlState}
                modificarRecursosUrlState={modificarRecursosUrlState}
                actualizarSessionStorage={actualizarSessionStorage}
                administradorState={administradorState}
                modificarAdministradorState={modificarAdministradorState}
              />
            )}
          />
          <Route
            exact
            path="/Pedido/Rechazado"
            render={() => (
              <PaymentRejected
                headerState={headerState}
                modificarHeaderState={modificarHeaderState}
                carrito={carrito}
                modificarCarritoState={modificarCarritoState}
                pedidoState={pedidoState}
                modificarPedidoState={modificarPedidoState}
                errorState={errorState}
                modificarErrorState={modificarErrorState}
                spinnerState={spinnerState}
                modificarSpinnerState={modificarSpinnerState}
                apiUrlState={apiUrlState}
                modificarApiUrlState={modificarApiUrlState}
                recursosUrlState={recursosUrlState}
                modificarRecursosUrlState={modificarRecursosUrlState}
                actualizarSessionStorage={actualizarSessionStorage}
                administradorState={administradorState}
                modificarAdministradorState={modificarAdministradorState}
              />
            )}
          />
          <Route
            exact
            path="/Pedido/Pendiente"
            render={() => (
              <PaymentPending
                headerState={headerState}
                modificarHeaderState={modificarHeaderState}
                carrito={carrito}
                modificarCarritoState={modificarCarritoState}
                pedidoState={pedidoState}
                modificarPedidoState={modificarPedidoState}
                errorState={errorState}
                modificarErrorState={modificarErrorState}
                spinnerState={spinnerState}
                modificarSpinnerState={modificarSpinnerState}
                apiUrlState={apiUrlState}
                modificarApiUrlState={modificarApiUrlState}
                recursosUrlState={recursosUrlState}
                modificarRecursosUrlState={modificarRecursosUrlState}
                actualizarSessionStorage={actualizarSessionStorage}
                administradorState={administradorState}
                modificarAdministradorState={modificarAdministradorState}
              />
            )}
          />
          <Route
            exact
            path="/Login"
            render={() => (
              <InicioSesion
                headerState={headerState}
                modificarHeaderState={modificarHeaderState}
                recursosUrlState={recursosUrlState}
                modificarRecursosUrlState={modificarRecursosUrlState}
                apiUrlState={apiUrlState}
                modificarApiUrlState={modificarApiUrlState}
                // clienteState={clienteState}
                // modificarClienteState={modificarClienteState}
                errorState={errorState}
                modificarErrorState={modificarErrorState}
                spinnerState={spinnerState}
                modificarSpinnerState={modificarSpinnerState}
                pedidoState={pedidoState}
                modificarPedidoState={modificarPedidoState}
                administradorState={administradorState}
                modificarAdministradorState={modificarAdministradorState}
                actualizarSessionStorage={actualizarSessionStorage}
              />
            )}
          />
          <Route
            exact
            path="/Ayuda"
            render={() => (
              <Ayuda
                headerState={headerState}
                modificarHeaderState={modificarHeaderState}
                ayudaState={ayudaState}
                modificarAyudaState={modificarAyudaState}
                recursosUrlState={recursosUrlState}
                modificarRecursosUrlState={modificarRecursosUrlState}
              />
            )}
          />
          <Route
            exact
            path="/Landing/:id"
            render={() => (
              <Landing
                actualizarSessionStorage={actualizarSessionStorage}
                modificarHeaderState={modificarHeaderState}
                recursosUrlState={recursosUrlState}
                headerState={headerState}
                modificarlandingState={modificarlandingState}
                apiUrlState={apiUrlState}
                SetLandingHeader={SetLandingHeader}
              />
            )}
          />
          <Route
            exact
            path="/Landing/:id/:Genero"
            render={() => (
              <Landing
                actualizarSessionStorage={actualizarSessionStorage}
                modificarHeaderState={modificarHeaderState}
                recursosUrlState={recursosUrlState}
                headerState={headerState}
                modificarlandingState={modificarlandingState}
                apiUrlState={apiUrlState}
                SetLandingHeader={SetLandingHeader}
              />
            )}
          />
          <Route
            exact
            path="/Admin"
            render={() => (
              <>
                <Dashboard
                  setAdminHeaderState={setAdminHeaderState}
                  headerState={headerState}
                  modificarHeaderState={modificarHeaderState}
                  ayudaState={ayudaState}
                  modificarAyudaState={modificarAyudaState}
                  recursosUrlState={recursosUrlState}
                  modificarRecursosUrlState={modificarRecursosUrlState}
                />
                <Indicadores
                  SetLandingHeader={SetLandingHeader}
                  apiUrlState={apiUrlState}
                  recursosUrlState={recursosUrlState}
                />
              </>
            )}
          />
          <Route
            exact
            path="/Admin/Index/MenuPrincipal"
            render={() => (
              <>
                <Dashboard
                  setAdminHeaderState={setAdminHeaderState}
                  headerState={headerState}
                  modificarHeaderState={modificarHeaderState}
                  ayudaState={ayudaState}
                  modificarAyudaState={modificarAyudaState}
                  recursosUrlState={recursosUrlState}
                  modificarRecursosUrlState={modificarRecursosUrlState}
                />
                <MenuPrincipal
                  SetLandingHeader={SetLandingHeader}
                  apiUrlState={apiUrlState}
                  recursosUrlState={recursosUrlState}
                />
              </>
            )}
          />
          <Route
            exact
            path="/Admin/Index/MenuUnidades"
            render={() => (
              <>
                <Dashboard
                  setAdminHeaderState={setAdminHeaderState}
                  headerState={headerState}
                  modificarHeaderState={modificarHeaderState}
                  ayudaState={ayudaState}
                  modificarAyudaState={modificarAyudaState}
                  recursosUrlState={recursosUrlState}
                  modificarRecursosUrlState={modificarRecursosUrlState}
                />
                <MenuUnidades
                  SetLandingHeader={SetLandingHeader}
                  apiUrlState={apiUrlState}
                  recursosUrlState={recursosUrlState}
                />
              </>
            )}
          />
          <Route
            exact
            path="/Admin/Indicadores"
            render={() => (
              <>
                <Dashboard
                  setAdminHeaderState={setAdminHeaderState}
                  headerState={headerState}
                  modificarHeaderState={modificarHeaderState}
                  ayudaState={ayudaState}
                  modificarAyudaState={modificarAyudaState}
                  recursosUrlState={recursosUrlState}
                  modificarRecursosUrlState={modificarRecursosUrlState}
                />
                <Indicadores
                  SetLandingHeader={SetLandingHeader}
                  apiUrlState={apiUrlState}
                  recursosUrlState={recursosUrlState}
                />
              </>
            )}
          />
          <Route
            exact
            path="/Admin/CrudIndicadores"
            render={() => (
              <>
                <Dashboard
                  setAdminHeaderState={setAdminHeaderState}
                  headerState={headerState}
                  modificarHeaderState={modificarHeaderState}
                  ayudaState={ayudaState}
                  modificarAyudaState={modificarAyudaState}
                  recursosUrlState={recursosUrlState}
                  modificarRecursosUrlState={modificarRecursosUrlState}
                />
                <CrudIndicadores
                  SetLandingHeader={SetLandingHeader}
                  apiUrlState={apiUrlState}
                />
              </>
            )}
          />
          <Route
            exact
            path="/Admin/DetalleMeta/:id"
            render={() => (
              <>
                <Dashboard
                  setAdminHeaderState={setAdminHeaderState}
                  headerState={headerState}
                  modificarHeaderState={modificarHeaderState}
                  ayudaState={ayudaState}
                  modificarAyudaState={modificarAyudaState}
                  recursosUrlState={recursosUrlState}
                  modificarRecursosUrlState={modificarRecursosUrlState}
                />
                <DetalleMeta
                  SetLandingHeader={SetLandingHeader}
                  apiUrlState={apiUrlState}
                />
              </>
            )}
          />
          <Route
            exact
            path="/Detalle/:id"
            render={() => (
              <Detalle
                apiUrlState={apiUrlState}
                recursosUrlState={recursosUrlState}
                actualizarSessionStorage={actualizarSessionStorage}
                carrito={carrito}
                errorState={errorState}
                headerState={headerState}
                modificarApiUrlState={modificarApiUrlState}
                modificarCarritoState={modificarCarritoState}
                modificarErrorState={modificarErrorState}
                modificarHeaderState={modificarHeaderState}
                modificarSpinnerState={modificarSpinnerState}
                spinnerState={spinnerState}
                SetLandingHeader={SetLandingHeader}
                SetViewDetalle={SetViewDetalle}
                ViewDetalle={ViewDetalle}
                tallasState={tallasState}
                modificarTallasState={modificarTallasState}
                vendedor={vendedor}
                modificarVendedorState={modificarVendedorState}
                Bodega={Bodega}
                setBodega={setBodega}
              />
            )}
          />
          <Route
            exact
            path="/Admin/Unidades"
            render={() => (
              <>
                <Dashboard
                  setAdminHeaderState={setAdminHeaderState}
                  headerState={headerState}
                  modificarHeaderState={modificarHeaderState}
                  ayudaState={ayudaState}
                  modificarAyudaState={modificarAyudaState}
                  recursosUrlState={recursosUrlState}
                  modificarRecursosUrlState={modificarRecursosUrlState}
                />
                <Unidades
                  SetLandingHeader={SetLandingHeader}
                  apiUrlState={apiUrlState}
                  recursosUrlState={recursosUrlState}
                />
              </>
            )}
          />
          <Route
            exact
            path="/Admin/Rotacion/:id"
            render={() => (
              <>
                <Dashboard
                  setAdminHeaderState={setAdminHeaderState}
                  headerState={headerState}
                  modificarHeaderState={modificarHeaderState}
                  ayudaState={ayudaState}
                  modificarAyudaState={modificarAyudaState}
                  recursosUrlState={recursosUrlState}
                  modificarRecursosUrlState={modificarRecursosUrlState}
                />
                <Rotacion
                  SetLandingHeader={SetLandingHeader}
                  apiUrlState={apiUrlState}
                  recursosUrlState={recursosUrlState}
                />
              </>
            )}
          />
          <Route
            exact
            path="/Admin/Imagenes"
            render={() => (
              <>
                <Dashboard
                  setAdminHeaderState={setAdminHeaderState}
                  headerState={headerState}
                  modificarHeaderState={modificarHeaderState}
                  ayudaState={ayudaState}
                  modificarAyudaState={modificarAyudaState}
                  recursosUrlState={recursosUrlState}
                  modificarRecursosUrlState={modificarRecursosUrlState}
                />
                <Imagenes apiUrlState={apiUrlState} />
              </>
            )}
          />
          <Route
            exact
            path="/Admin/Descuentos"
            render={() => (
              <>
                <Dashboard
                  setAdminHeaderState={setAdminHeaderState}
                  headerState={headerState}
                  modificarHeaderState={modificarHeaderState}
                  ayudaState={ayudaState}
                  modificarAyudaState={modificarAyudaState}
                  recursosUrlState={recursosUrlState}
                  modificarRecursosUrlState={modificarRecursosUrlState}
                />
                <Descuentos
                  apiUrlState={apiUrlState}
                />
              </>
            )}
          />
          <Route
            exact
            path="/Admin/Rotacion"
            render={() => (
              <>
                <Dashboard
                  setAdminHeaderState={setAdminHeaderState}
                  headerState={headerState}
                  modificarHeaderState={modificarHeaderState}
                  ayudaState={ayudaState}
                  modificarAyudaState={modificarAyudaState}
                  recursosUrlState={recursosUrlState}
                  modificarRecursosUrlState={modificarRecursosUrlState}
                />
                <RotacionSeller
                  SetLandingHeader={SetLandingHeader}
                  apiUrlState={apiUrlState}
                  recursosUrlState={recursosUrlState}
                  modificarSpinnerState={modificarSpinnerState}
                  spinnerState={spinnerState}
                />
              </>
            )}
          />
          <Route
            exact
            path="/Admin/Whatsapp"
            render={() => (
              <>
                <Dashboard
                  setAdminHeaderState={setAdminHeaderState}
                  headerState={headerState}
                  modificarHeaderState={modificarHeaderState}
                  ayudaState={ayudaState}
                  modificarAyudaState={modificarAyudaState}
                  recursosUrlState={recursosUrlState}
                  modificarRecursosUrlState={modificarRecursosUrlState}
                />
                <Whatsapp apiUrlState={apiUrlState} />
              </>
            )}
          />
          <Route
            exact
            path="/Admin/Rotacion/Seller/:periodo"
            render={() => (
              <>
                <Dashboard
                  setAdminHeaderState={setAdminHeaderState}
                  headerState={headerState}
                  modificarHeaderState={modificarHeaderState}
                  ayudaState={ayudaState}
                  modificarAyudaState={modificarAyudaState}
                  recursosUrlState={recursosUrlState}
                  modificarRecursosUrlState={modificarRecursosUrlState}
                />
                <RotacionIndex
                  SetLandingHeader={SetLandingHeader}
                  apiUrlState={apiUrlState}
                  recursosUrlState={recursosUrlState}
                  modificarSpinnerState={modificarSpinnerState}
                  spinnerState={spinnerState}
                />
              </>
            )}
          />
          <Route
            exact
            path="/Concursonacionaldediseno"
            render={() => (
              <>
                <Dashboard
                  setMenuView={true}
                  setAdminHeaderState={setAdminHeaderState}
                  headerState={headerState}
                  modificarHeaderState={modificarHeaderState}
                  ayudaState={ayudaState}
                  modificarAyudaState={modificarAyudaState}
                  recursosUrlState={recursosUrlState}
                  modificarRecursosUrlState={modificarRecursosUrlState}
                />
                <RegistroTercero
                  headerState={headerState}
                  modificarHeaderState={modificarHeaderState}
                  pedidoState={pedidoState}
                  modificarPedidoState={modificarPedidoState}
                  // clienteState={clienteState}
                  // modificarClienteState={modificarClienteState}
                  errorState={errorState}
                  modificarErrorState={modificarErrorState}
                  spinnerState={spinnerState}
                  modificarSpinnerState={modificarSpinnerState}
                  apiUrlState={apiUrlState}
                  modificarApiUrlState={modificarApiUrlState}
                  recursosUrlState={recursosUrlState}
                  modificarRecursosUrlState={modificarRecursosUrlState}
                  actualizarSessionStorage={actualizarSessionStorage}
                />
              </>
            )}
          />
          <Route
            exact
            path="/Preventa"
            render={() => (
              <>
                <Dashboard
                  setMenuView={true}
                  setAdminHeaderState={setAdminHeaderState}
                  headerState={headerState}
                  modificarHeaderState={modificarHeaderState}
                  ayudaState={ayudaState}
                  modificarAyudaState={modificarAyudaState}
                  recursosUrlState={recursosUrlState}
                  modificarRecursosUrlState={modificarRecursosUrlState}
                />
                <LoginVendedor
                  apiUrlState={apiUrlState}
                  modificarApiUrlState={modificarApiUrlState}
                  headerState={headerState}
                  modificarHeaderState={modificarHeaderState}
                  errorState={errorState}
                  modificarErrorState={modificarErrorState}
                  vendedor={vendedor}
                  modificarVendedorState={modificarVendedorState}
                  actualizarSessionStorage={actualizarSessionStorage}
                />
              </>
            )}
          />
          <Route
            exact
            path="/Preventa/Catalogo"
            render={() => (
              <CategoriasProvider>
                <Mercado
                  apiUrlState={apiUrlState}
                  carrito={carrito}
                  CatalogoState={false}
                  categoriasState={categoriasState}
                  // clienteState={clienteState}
                  coloresState={coloresState}
                  errorState={errorState}
                  filtrosState={filtrosState}
                  headerState={headerState}
                  marcasState={marcasState}
                  medidasState={medidasState}
                  modificarApiUrlState={modificarApiUrlState}
                  modificarCarritoState={modificarCarritoState}
                  modificarCategoriasState={modificarCategoriasState}
                  // modificarClienteState={modificarClienteState}
                  modificarColoresState={modificarColoresState}
                  modificarErrorState={modificarErrorState}
                  modificarFiltrosState={modificarFiltrosState}
                  subCategoriasState={subCategoriasState}
                  modificarSubCategoriasState={modificarSubCategoriasState}
                  modificarHeaderState={modificarHeaderState}
                  modificarMarcasState={modificarMarcasState}
                  modificarMedidasState={modificarMedidasState}
                  modificarProductosState={modificarProductosState}
                  modificarRecursosUrlState={modificarRecursosUrlState}
                  modificarSpinnerState={modificarSpinnerState}
                  modificarTallasState={modificarTallasState}
                  productosState={productosState}
                  recursosUrlState={recursosUrlState}
                  spinnerState={spinnerState}
                  tallasState={tallasState}
                  actualizarSessionStorage={actualizarSessionStorage}
                  rutaDinamica={true}
                  ViewDetalle={ViewDetalle}
                  refNoCatalogoState={refNoCatalogoState}
                  modificarRefNoCatalogoState={modificarRefNoCatalogoState}
                  vendedor={vendedor}
                  modificarVendedorState={modificarVendedorState}
                  Bodega={Bodega}
                  setBodega={setBodega}
                  BodegaCatalogos={BodegaCatalogos}
                  setBodegaCatalogos={setBodegaCatalogos}
                  checkedProductos={checkedProductos}
                  setCheckedProductos={setCheckedProductos}
                />
              </CategoriasProvider>
            )}
          />
        </Switch>
      </Router>
    </GlobalStatesProvider>
    </Fragment>
  );
}

export default App;
