import axios from 'axios'
import { useForm } from 'react-hook-form'
import { useState, useEffect, useRef } from 'react'
import { useGlobalContext } from '../../providers/GlobalStatesProviders'
import './static/css/Registro/FormRegistro.css'

const FormRegistro = ({
  apiUrlState,
  headerState,
  actualizarSessionStorage,
  spinnerState,
  modificarSpinnerState,
  actualizar,
  setActualizar,
  vendedor,
}) => {

  const { clienteState, modificarClienteState } = useGlobalContext();

   /** Estado para manejo del formulario con react-hook-form */
   const { register, handleSubmit, setValue, watch ,formState: {
    errors
  } } = useForm();

  const [departamentos, setDepartamentos] = useState([]);
  const [ciudades, setCiudades] = useState([]);
  const [vinculado, setVinculado] = useState(null);
  const [sucursales, setSucursales] = useState([]);
  const [selectSucursal, setSelectSucursal] = useState(null);
  const [consulta, setConsulta] = useState(false);

  /** Controla la ejecucion de consulta de vinculado para 
   *  que no se ejecute con cada pulsacion de teclado */
  const timeoutRef = useRef(null);

/** Habilita la actualizacion de vinculado unicamente cuando
 *  se valida el formulario */
  const step = useRef(false);

  const consultarDepartamentos = async (Pais) => {
    modificarSpinnerState({
      Desplegar: true,
      Texto: "Consultando Departamentos..."
    });
    await axios
      .post(apiUrlState + "/Api/Departamento/ObtenerDepartamentos", {
        data: {
          Pais: Pais,
        },
      }).then((response)=>{
        setDepartamentos(response.data.data);
        modificarSpinnerState({
          Desplegar: false,
          Texto: "Consultando Departamentos..."
        });
      }).catch((error)=>{
        modificarSpinnerState({
          Desplegar: false,
          Texto: "Consultando Departamentos..."
        });
        // Desplegar error
      });
  }

  const consultarCiudades = async (Departamento) => {
    modificarSpinnerState({
      Desplegar: true,
      Texto: "Consultando Ciudades..."
    });
    await axios
    .post(apiUrlState + "/Api/Ciudad/ObtenerCiudades", {
      data: {
        Departamento: Departamento,
      },
    })
    .then((response) => {
      setCiudades(response.data.data);
      modificarSpinnerState({
        Desplegar: false,
        Texto: "Consultando Ciudades..."
      });
    }).catch((error)=> {
      modificarSpinnerState({
        Desplegar: false,
        Texto: "Consultando Ciudades..."
      });
      // Desplegar error
    })
  }

  /**Comprueba el numero de documento y consulta para ver
   * si es cliente registrado. */
  const handleChangeDoc = (e) => {
    const value = e.target.value;    
    const doc = value.replace(/[^0-9]/g, '');
    setValue("Documento", doc);
    if(doc.length > 5 && doc.length < 14) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(()=> {
        consultarVinculado(doc);
      }, 1000);
    }
  };

  const consultarVinculado =  async (documento) => {
    modificarSpinnerState({
      Desplegar: true,
      Texto: "Consultando Cliente..."
    });
    // Si se esta digitando una identificación, no utiliza el cliente local
    setConsulta(true);
    const url = `${apiUrlState}/Api/Usuario/GetInfoUsuarioMgerV2/${documento}`;
    const response = await axios.get(url);
    if(response.data.existe) {
      setVinculado(response.data.data);
      setSucursales(response.data.Sucursales);
    } else {
      setVinculado("");
      setVinculado(null);
      setSucursales([]);
      setSelectSucursal(null);
    }
    modificarSpinnerState({
      Desplegar: false,
      Texto: "Consultando Cliente..."
    });
  }

  /** Se utiliza solo para setear las sucursales cuando se carga el vinculado
   * desde el localStorage
   */
  const consultarSucursales = async (documento) => {
    const url = `${apiUrlState}/Api/Usuario/GetInfoUsuarioMgerV2/${documento}`;
    const response = await axios.get(url);
    if(response.data.existe) {
      setSucursales(response.data.Sucursales);
    } else {
      setSucursales([]);
    }
  }

  const handleChangeSucursal = (value) => {
    const idSucursal = value;
    if(idSucursal !== "0") {
      const sucursal = sucursales.find(suc => suc.Identificador === idSucursal);
      setSelectSucursal(sucursal);      
    } else {
      setSelectSucursal(null);
    }
  }

  /**
   * Diligencia el formulario de vinculado con los datos de la Api o con los
   * obtenidos del localStorage.
   * @param consultar Boleano para resolver si se llena el form con el vinculado
   * o con el cliente local. consultado: true -> Datos desde Api.
   */
  const llenarFormVinculado = async(consultado) => {
    modificarSpinnerState({
      Desplegar: true,
      Texto: "Consultando datos del cliente..."
    });
    const storageClient = localStorage.getItem("Cliente");
    const clienteLocal = storageClient==="undefined"
      ? ""
      : JSON.parse(localStorage.getItem("Cliente"));
    if(clienteLocal && !consultado) {
      consultarSucursales(clienteLocal.NumeroDocumento);
    }
    let initialValues = {
      Documento: clienteLocal? clienteLocal.NumeroDocumento : '',
      TipoDocumento: clienteLocal? clienteLocal.TipoDocumento : '',
      Nombre: clienteLocal? clienteLocal.Nombres : '',
      Apellido: clienteLocal? clienteLocal.Apellidos : '',
      // Sucursal: '0',
      Telefono: clienteLocal? clienteLocal.Celular : '',
      FechaNacimiento: clienteLocal? clienteLocal.FechaNacimiento : '',
      Email: clienteLocal? clienteLocal.Correo : '',
      Genero: clienteLocal? clienteLocal.Genero : '',
      Pais: clienteLocal? clienteLocal.Pais : '',
      Departamento: clienteLocal? clienteLocal.Departamento : '',
      Ciudad: clienteLocal? clienteLocal.Ciudad : '',
      Direccion: clienteLocal? clienteLocal.Direccion : '',
      Barrio: clienteLocal? clienteLocal.Barrio : '',
    }
    if(consultado) {
      let fecha = '';
      if(vinculado!=null && vinculado.FechaNacimiento!=undefined) {
        fecha = new Date(vinculado.FechaNacimiento).toISOString().split('T')[0];
      }

      initialValues = {
        // Documento: vinculado? vinculado.NumeroDocumento : "",
        TipoDocumento: vinculado? vinculado.TipoDocumento : "",
        Nombre: vinculado? vinculado.Nombres : "",
        Apellido: vinculado? vinculado.Apellidos : "",
        // Sucursal: "0",
        Telefono: vinculado? vinculado.Celular : "",
        FechaNacimiento: fecha,
        Email: vinculado? vinculado.Correo : "",
        Genero: vinculado? vinculado.Genero : "",
        Pais: vinculado? vinculado.Ciudad.Departamento.Pais._id : "",
        Departamento: vinculado? vinculado.Ciudad.Departamento._id : "",
        Ciudad: vinculado? vinculado.Ciudad._id : "",
        Direccion: vinculado? vinculado.Direccion : "",
        Barrio: vinculado? vinculado.Barrio : "",
      }
    }
    if(initialValues.Pais !== "") {
      await consultarDepartamentos(initialValues.Pais);
    }
    if(initialValues.Departamento !== "") {
      await consultarCiudades(initialValues.Departamento);
    }
    Object.keys(initialValues).forEach((key) => {
      setValue(key, initialValues[key]);
    });
    if(vinculado !== null && consultado) {
      actualizarClienteState();
    }
    modificarSpinnerState({
      Desplegar: false,
      Texto: "Consultando datos del cliente..."
    });
  }

  const llenarFormSucursal = async() => {
    modificarSpinnerState({
      Desplegar: true,
      Texto: "Consultando sucursal..."
    });
      let initialValues = {
        Snombre: selectSucursal.Nombres,
        Semail: selectSucursal.Correo,
        Stelefono: selectSucursal.Celular,
        Spais: selectSucursal.Ciudad.Departamento.Pais._id,
        Sdepartamento: selectSucursal.Ciudad.Departamento._id,
        Sciudad: selectSucursal.Ciudad._id,
        Sdireccion: selectSucursal.Direccion,
        Sbarrio: selectSucursal.Barrio,
      }
      await consultarDepartamentos(initialValues.Spais);
      await consultarCiudades(initialValues.Sdepartamento);
  
      Object.keys(initialValues).forEach((key) => {
        setValue(key, initialValues[key]);
      });
      modificarSpinnerState({
        Desplegar: false,
        Texto: "Consultando sucursal..."
      });
  }

  /** Actualiza el State y el localStorage de cliente con los datos
   *  actuales del formulario */
  const actualizarClienteState = () => {
    const cliente = {
      // ...vinculado,
      Registro: false,
      Accion: "Activo",
      Sesion: true, // Revisar cuando no se llena automatico
      NumeroDocumento: watch("Documento"),
      TipoDocumento: watch("TipoDocumento"),
      Nombres: watch("Nombre"),
      Apellidos: watch("Apellido"),
      Sucursal: !selectSucursal ? "0" : selectSucursal.Identificador,
      Celular: watch("Telefono"),
      FechaNacimiento: watch("FechaNacimiento"),
      Correo: watch("Email"),
      Genero: watch("Genero"),
      Pais: watch("Pais"),
      Departamento: watch("Departamento"),
      Ciudad: watch("Ciudad"),
      Direccion: watch("Direccion"),
      Barrio: watch("Barrio"),
    };
    actualizarSessionStorage("Cliente", JSON.stringify(cliente));
    modificarClienteState({...cliente});
  }

  const actualizarSucursal = async () => {
    if(selectSucursal !== null) {
      modificarSpinnerState({
        Desplegar: true,
        Texto: "Actualizando Sucursal..."
      });
      const dataSucursal = {
        NuevosDatosUsuario: [
          {
            NumeroDocumento: watch("Documento"),
            Correo: watch("Semail"),
            Nombres: watch("Snombre"),
            Apellidos: "",
            Celular: watch("Stelefono"),
            Pais: watch("Spais"),
            Departamento: watch("Sdepartamento"),
            Ciudad: watch("Sciudad"),
            Direccion: watch("Sdireccion"),
            Barrio: watch("Sbarrio"),
            TipoTercero: headerState["Cliente"],
            Sucursal: selectSucursal.Identificador,
          },
        ],
      }
      const url = `${apiUrlState}/Api/Usuario/ActualizarSucursal`;
      const response = await axios.post(url, dataSucursal);
      // console.log("Respuesta update sucursal", response);
      modificarSpinnerState({
        Desplegar: false,
        Texto: "Actualizando Sucursal..."
      });      
    }
  }

  /** Realiza la verificación del fomulario y ejecuta la actualización */
  const onSubmit = handleSubmit(async ()=> {
    await actualizarSucursal();
    actualizarClienteState();
    step.current = true;
  })

  /** Se ejecuta cuando se cambia el estado de {actualizar} desde 
   * el componente pedido con el boton continuar compra */
  useEffect(() => {
    if(actualizar.Ejecucion) {
      onSubmit();
      setActualizar({...actualizar, Ejecucion: false});
    }  
  }, [actualizar])
  

  /** Se ejecuta despues de actualizar la sucursal para que inicie
   *  la actualizacion de datos del vinculado del componente pedido */
  useEffect(() => {
    if(step.current) {
      setActualizar({...actualizar, Usuario: true});
    }
    step.current = false;
  }, [clienteState]);

  /** Llena el form de vinculado con los datos obtenidos
   *  desde la Api */
  useEffect(() => {
    llenarFormVinculado(true);
  }, [vinculado])

  /** Llena el form de la sucursal o del cliente
   *  dependiendo de la sucursal seleccionada. */
  useEffect(() => {
    if(selectSucursal) {
      llenarFormSucursal();
    } else {
      llenarFormVinculado(consulta)
    }
  },[selectSucursal])

  /** Si el vinculado tiene sucursales, se selecciona
   * alguna por defecto.
   */
  useEffect(() => {
    if(sucursales.length != 0) {
      setValue("Sucursal", sucursales[0].Identificador);
      handleChangeSucursal(sucursales[0].Identificador);
    } else {
      setValue("Sucursal", "0");
      handleChangeSucursal("0");
    }
  }, [sucursales])

  return (
    <>
      <div className="div-container">
        <form className="form-info" onSubmit={onSubmit}>
          <div className="form-display">
            <div className="form-column">
              <div>
                <label htmlFor="documento">(*) Número documento</label>
                <input
                  id="documento"
                  type="text"
                  placeholder="Ingrese el número de documento"
                  {...register("Documento", {
                    required: {
                      value: true,
                      message: "El Documento es requerido",
                    },
                    minLength: {
                      value: 6,
                      message: "El documento debe tener al menos 6 dígitos",
                    },
                    maxLength: {
                      value: 13,
                      message: "El documento no debe tener mas de 13 dígitos",
                    },
                    pattern: {
                      value: /[0-9]/,
                      message:
                        "El numero de documento debe ser un numero sin espacios",
                    },
                  })}
                  onChange={(e) => handleChangeDoc(e)}
                />
                {errors.Documento && (
                  <span className="spanError">{errors.Documento.message}</span>
                )}
              </div>
              <div>
                <label htmlFor="tipoDocumento">(*) Tipo documento</label>
                <select
                  id="tipoDocumento"
                  type="text"
                  placeholder="Seleccione el tipo de documento"
                  {...register("TipoDocumento", {
                    required: {
                      value: true,
                      message: "El Tipo de documento es requerido",
                    },
                  })}
                >
                  <option value="" hidden className="options">
                    Seleccione su tipo de identificación
                  </option>
                  <option value="13">Cédula</option>
                  <option value="31">Nit</option>
                  {/* <option value=""></option> */}
                </select>
                {errors.TipoDocumento && (
                  <span className="spanError">
                    {errors.TipoDocumento.message}
                  </span>
                )}
              </div>
              <div>
                <label htmlFor="nombre">(*) Nombres</label>
                <input
                  id="nombre"
                  type="text"
                  placeholder="Ingrese su nombre"
                  {...register("Nombre", {
                    required: {
                      value: true,
                      message: "El Nombre es requerido",
                    },
                    minLength: {
                      value: 3,
                      message: "Su nombre debe tener al menos 3 caracteres",
                    },
                  })}
                />
                {errors.Nombre && (
                  <span className="spanError">{errors.Nombre.message}</span>
                )}
              </div>
              <div>
                <label htmlFor="apellido">(*) Apellidos</label>
                <input
                  id="apellido"
                  type="text"
                  placeholder="Ingrese su apellido"
                  {...register("Apellido", {
                    validate: (value) => {
                      return (
                        (watch("TipoDocumento") === "31" || value !== "")  ||
                        "El Apellido es requerido"
                      );
                    },
                  })}
                />
                {errors.Apellido && (
                  <span className="spanError">{errors.Apellido.message}</span>
                )}
              </div>
              {vendedor? (
              <div>
                <label htmlFor="sucursal">(*) Sucursal</label>
                <select
                  id="sucursal"
                  style={{ color: "blue" }}
                  {...register("Sucursal", {
                    required: {
                      value: true,
                      message: "La Sucursal es requerida",
                    },
                  })}
                  onChange={(e) => {handleChangeSucursal(e.target.value)}}
                >
                  {sucursales.length == 0? (
                  <option value="0">Principal</option>
                  ) : null}
                  {sucursales.map((sucursal) => (
                    <option
                      key={sucursal.Identificador}
                      value={sucursal.Identificador}
                    >
                      {sucursal.Nombres}
                    </option>
                  ))}
                </select>
              </div>
              ) : null}
              <div>
                <label htmlFor="telefono">(*) Teléfono</label>
                <input
                  id="telefono"
                  type="text"
                  placeholder="Ingrese su teléfono"
                  {...register("Telefono", {
                    required: {
                      value: true,
                      message: "El Teléfono es requerido",
                    },
                    minLength: {
                      value: 7,
                      message: "El Teléfono debe tener al menos 7 dígitos sin espacios",
                    },
                    maxLength: {
                      value: 10,
                      message: "El Teléfono debe tener menos de 10 dígitos sin espacios",
                    },
                  })}
                />
                {errors.Telefono && (
                  <span className="spanError">{errors.Telefono.message}</span>
                )}
              </div>
              <div>
                <label htmlFor="fechaNacimiento">
                  (*) Fecha nacimiento (<small>opcional</small>)
                </label>
                <input
                  id="fechaNacimiento"
                  type="date"
                  {...register("FechaNacimiento", {
                    required: {
                      value: false,
                      message: "Este campo es opcional",
                    },
                  })}
                />
                {errors.FechaNacimiento && (
                  <span className="spanError">
                    {errors.FechaNacimiento.message}
                  </span>
                )}
              </div>
            </div>

            {/* Form-rigth */}
            {!selectSucursal? (
            <div className="form-column">
              <div>
                <label htmlFor="email">(*) Correo electrónico</label>
                <input
                  id="email"
                  type="email"
                  placeholder="Ingresa tu Correo"
                  {...register("Email", {
                    required: {
                      value: true,
                      message: "El Correo es requerido",
                    },
                    pattern: {
                      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                      message: "Correo electrónico no válido",
                    },
                  })}
                />
                {errors.Email && (
                  <span className="spanError">{errors.Email.message}</span>
                )}
              </div>
              <div>
                <label htmlFor="Genero">(*) Género</label>
                <select
                  id="Genero"
                  {...register("Genero", {
                    required: {
                      value: true,
                      message: "El Genero es requerido",
                    },
                  })}
                >
                  <option hidden value="">
                    Seleccione su género
                  </option>
                  <option value="M">Masculino</option>
                  <option value="F">Femenino</option>
                </select>
                {errors.Genero && (
                  <span className="spanError">{errors.Genero.message}</span>
                )}
              </div>
              <div>
                <label htmlFor="pais">(*) País</label>
                <select
                  id="pais"
                  placeholder="Seleccione el País"
                  {...register("Pais", {
                    required: {
                      value: true,
                      message: "El País es requerido",
                    },
                  })}
                  onChange={(e) => {
                    consultarDepartamentos(e.target.value);
                  }}
                >
                  <option hidden value="">
                    Seleccione su país
                  </option>
                  <option value="60c8c846e76f272f6835f153">Colombia</option>
                </select>
                {errors.Pais && (
                  <span className="spanError">{errors.Pais.message}</span>
                )}
              </div>
              <div>
                <label htmlFor="departamento">(*) Departamento</label>
                <select
                  id="departamento"
                  placeholder="Seleccione Departamento"
                  {...register("Departamento", {
                    required: {
                      value: true,
                      message: "El Departamento es requerido",
                    },
                  })}
                  onChange={(e) => {
                    consultarCiudades(e.target.value);
                  }}
                >
                  <option hidden value="">
                    Seleccione su departamento
                  </option>
                  {departamentos.map((dpto) => (
                    <option key={dpto.Codigo} value={dpto._id}>
                      {dpto.Nombre}
                    </option>
                  ))}
                </select>
                {errors.Departamento && (
                  <span className="spanError">
                    {errors.Departamento.message}
                  </span>
                )}
              </div>
              <div>
                <label htmlFor="ciudad">(*) Ciudad</label>
                <select
                  id="ciudad"
                  placeholder="Seleccione Ciudad"
                  {...register("Ciudad", {
                    required: {
                      value: true,
                      message: "La Ciudad es requerida",
                    },
                  })}
                >
                  <option hidden value="">
                    Seleccione su ciudad
                  </option>
                  {ciudades.map((ciudad) => (
                    <option key={ciudad.Codigo} value={ciudad._id}>
                      {ciudad.Nombre}
                    </option>
                  ))}
                </select>
                {errors.Ciudad && (
                  <span className="spanError">{errors.Ciudad.message}</span>
                )}
              </div>
              <div>
                <label htmlFor="direccion">(*) Dirección</label>
                <input
                  id="direccion"
                  type="text"
                  placeholder="Ingrese su dirección"
                  {...register("Direccion", {
                    required: {
                      value: true,
                      message: "La Dirección es requerida",
                    },
                    maxLength: {
                      value: 40,
                      message: "Este campo no puede tener mas de 40 caracteres",
                    },
                  })}
                />
                {errors.Direccion && (
                  <span className="spanError">{errors.Direccion.message}</span>
                )}
              </div>
              <div>
                <label htmlFor="barrio">(*) Barrio</label>
                <input
                  id="barrio"
                  type="text"
                  placeholder="Ingrese el barrio"
                  {...register("Barrio", {
                    required: {
                      value: true,
                      message: "La Barrio es requerido",
                    },
                    maxLength: {
                      value: 25,
                      message: "Este campo no puede tener mas de 25 caracteres",
                    },
                  })}
                />
                {errors.Barrio && (
                  <span className="spanError">{errors.Barrio.message}</span>
                )}
              </div>
            </div>
            ) : (

            /* Formulario de sucursal */
            <div className="form-column">
              <div>
                <label htmlFor="snombre">(*) Nombre sucursal</label>
                <input
                  type="text"
                  id="snombre"
                  {...register("Snombre",{
                    required: {
                      value: true,
                      message: "El Nombre de la sucursal es requerido"
                    },
                    minLength: {
                      value: 3,
                      message: "Su nombre debe tener al menos 3 caracteres",
                    }
                  })}
                />
                {errors.Snombre && (
                  <span className="spanError">{errors.Snombre.message}</span>
                )}
              </div>
              <div>
                <label htmlFor="semail">(*) Correo sucursal</label>
                <input
                  type="text"
                  id="semail"
                  {...register("Semail",{
                    required: {
                      value: true,
                      message: "El correo de la sucursal es requerido"
                    },
                    pattern: {
                      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                      message: "Correo electrónico no válido",
                    },
                  })}
                />
                {errors.Semail && (
                  <span className="spanError">{errors.Semail.message}</span>
                )}
              </div>
              <div>
                <label htmlFor="stelefono">Teléfono sucursal</label>
                <input
                  type="text"
                  id="stelefono"
                  {...register("Stelefono",{
                    required: {
                      value: true,
                      message: "El teléfono de la sucursal es requerido"
                    },
                    minLength: {
                      value: 7,
                      message: "El Teléfono debe tener al menos 7 dígitos sin espacios",
                    },
                    maxLength: {
                      value: 10,
                      message: "El Teléfono debe tener menos de 10 dígitos sin espacios",
                    }
                  })}
                />
                {errors.Stelefono && (
                  <span className="spanError">{errors.Stelefono.message}</span>
                )}
              </div>
              <div>
                <label htmlFor="spais">(*) País</label>
                <select
                  id="spais"
                  placeholder="Seleccione el País"
                  {...register("Spais", {
                    required: {
                      value: true,
                      message: "El País es requerido",
                    },
                  })}
                  onChange={(e) => {
                    consultarDepartamentos(e.target.value);
                  }}
                >
                  <option hidden value="">
                    Seleccione su país
                  </option>
                  <option value="60c8c846e76f272f6835f153">Colombia</option>
                </select>
                {errors.Spais && (
                  <span className="spanError">{errors.Spais.message}</span>
                )}
              </div>
              <div>
                <label htmlFor="sdepartamento">(*) Departamento</label>
                <select
                  id="sdepartamento"
                  placeholder="Seleccione Departamento"
                  {...register("Sdepartamento", {
                    required: {
                      value: true,
                      message: "El Departamento es requerido",
                    },
                  })}
                  onChange={(e) => {
                    consultarCiudades(e.target.value);
                  }}
                >
                  <option hidden value="">
                    Seleccione su departamento
                  </option>
                  {departamentos.map((dpto) => (
                    <option key={dpto.Codigo} value={dpto._id}>
                      {dpto.Nombre}
                    </option>
                  ))}
                </select>
                {errors.Sdepartamento && (
                  <span className="spanError">
                    {errors.Sdepartamento.message}
                  </span>
                )}
              </div>
              <div>
                <label htmlFor="sciudad">(*) Ciudad</label>
                <select
                  id="sciudad"
                  placeholder="Seleccione Ciudad"
                  {...register("Sciudad", {
                    required: {
                      value: true,
                      message: "La Ciudad es requerida",
                    },
                  })}
                >
                  <option hidden value="">
                    Seleccione su ciudad
                  </option>
                  {ciudades.map((ciudad) => (
                    <option key={ciudad.Codigo} value={ciudad._id}>
                      {ciudad.Nombre}
                    </option>
                  ))}
                </select>
                {errors.Sciudad && (
                  <span className="spanError">{errors.Sciudad.message}</span>
                )}
              </div>
              <div>
                <label htmlFor="sdireccion">(*) Dirección</label>
                <input
                  id="sdireccion"
                  type="text"
                  placeholder="Ingrese su dirección"
                  {...register("Sdireccion", {
                    required: {
                      value: true,
                      message: "La Dirección es requerida",
                    },
                    maxLength: {
                      value: 40,
                      message: "Este campo no puede tener mas de 40 caracteres",
                    },
                  })}
                />
                {errors.Sdireccion && (
                  <span className="spanError">{errors.Sdireccion.message}</span>
                )}
              </div>
              <div>
                <label htmlFor="sbarrio">(*) Barrio</label>
                <input
                  id="sbarrio"
                  type="text"
                  placeholder="Ingrese el barrio"
                  {...register("Sbarrio", {
                    required: {
                      value: true,
                      message: "La Barrio es requerido",
                    },
                    maxLength: {
                      value: 25,
                      message: "Este campo no puede tener mas de 25 caracteres",
                    },
                  })}
                />
                {errors.Sbarrio && (
                  <span className="spanError">{errors.Sbarrio.message}</span>
                )}
              </div>
            </div>
            )}

          </div>
        </form>
      </div>
    </>
  );
}

export default FormRegistro;