import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import "../static/Indicadores.css";
import { useParams } from "react-router";
import { Doughnut } from "react-chartjs-2";

const DetalleMeta = ({ SetLandingHeader, apiUrlState }) => {
  const [ListMeta, GetListMeta] = useState({
    IngresosBancarios: "$ --",
    IngresosEfectivo: "$ --",
    IngresosTotales: "$ --",
    Meta: "$ --",
    NoVencido: "$ --",
    Periodo: "--",
    TotalCxC: "$ --",
    TotalPorVender: "$ --",
    TotalVencido: "$ --",
    VencidoMas90Dias: "$ --",
    VencidoMax30Dias: "$ --",
    VencidoMax60Dias: "$ --",
    VencidoMax90Dias: "$ --",
    abonosVentasCredito: "$ --",
    ventasCredito: "$ --",
    Ventas: {
      Vendedor1: {Ventas: "$ --", Devoluciones: "$ --", Total: "$ --"},
      Vendedor2: {Ventas: "$ --", Devoluciones: "$ --", Total: "$ --"},
      VendedorWeb: {Ventas: "$ --", Devoluciones: "$ --", Total: "$ --"},
      Maquila: {Ventas: "$ --", Devoluciones: "$ --", Total: "$ --"},
    }
  });

  const token = localStorage.getItem("token");
  const history = useHistory();
  useEffect(() => {
    if (token) {
      
    }else{
      history.push("/login")
    }
    //console.log(reports)
  },[token]);

  let { id } = useParams();

  const obtenerDetalle = async () => {
    await axios
      .get(apiUrlState + "/Api/Indicadores/CalcularMeta/" + id)
      .then((response) => {
        GetListMeta(response.data);
        console.log("DATA: ",response.data)
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  const formatNumber = (amount) => {
    if (ListMeta.IngresosBancarios != "$ --") {
      var temp = Math.round(amount) + "";
      var i = temp.length - 3;
      while (i > 0) {
        temp = temp.substring(0, i) + "." + temp.substring(i);
        i -= 3;
      }
      return " " + temp;
    }
  };

  const dataVentas = {
    labels: ["Vendido", "Por Vender"],
    datasets: [
      {
        data: [ListMeta.IngresosTotales, ListMeta.TotalPorVender],
        backgroundColor: ["rgba(0, 255, 0, 1)", "rgba(255, 0, 23, 1)"],
        borderColor: ["rgba(75, 192, 192, 1)", "rgba(255, 99, 132, 1)"],
        borderWidth: 1,
      },
    ],
  };

  useEffect(() => {
    SetLandingHeader({ Estado: true });
    obtenerDetalle();
  }, []);
  return (
    <div className="container" style={{ maxWidth: "100%" }}>
      <div className="row">
        <div className="col-12 ">
          <h1
            className="Periodo"
            style={{
              marginTop: "150px",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            {ListMeta.Periodo}
          </h1>
        </div>
      </div>
      <div className="row">
        <div
          className="col-12 col-sm-12 col-md-12 col-lg-6"
          style={{ marginTop: "100px" }}
        >
          <div className="row" style={{ justifyContent: "center" }}>
            <div className="col-12 col-sm-6 col-md-6 col-lg-6">
              <Doughnut data={dataVentas} width={10} height={10} />
            </div>
          </div>
          <div className="row" style={{ marginTop: "50px" }}>
            <div
              className="col-6 col-sm-6 col-md-6 col-lg-6"
              style={{ textAlign: "center" }}
            >
              <p>Vendido: $ {formatNumber(ListMeta.IngresosTotales)}</p>
            </div>
            <div
              className="col-6 col-sm-6 col-md-6 col-lg-6"
              style={{ textAlign: "center" }}
            >
              <p>Por Vender: $ {formatNumber(ListMeta.TotalPorVender)}</p>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table">
              <thead style={{ marginTop: "50px", textAlign: "center" }}>
                <tr>
                  <th
                    className="textoTablas"
                    scope="col"
                    style={{ fontWeight: "bold" }}
                  >
                    Ingresos Bancarios
                  </th>
                  <th
                    className="textoTablas"
                    scope="col"
                    style={{ fontWeight: "bold" }}
                  >
                    Ingresos Efectivo
                  </th>
                  <th
                    className="textoTablas"
                    scope="col"
                    style={{ fontWeight: "bold" }}
                  >
                    Ventas a Credito
                  </th>
                  <th
                    className="textoTablas"
                    scope="col"
                    style={{ fontWeight: "bold" }}
                  >
                    Abonos a Ventas a Credito
                  </th>
                </tr>
              </thead>
              <tbody style={{ textAlign: "center" }}>
                <tr>
                  <td scope="row" className="textoTablas">
                    $ {formatNumber(ListMeta.IngresosBancarios)}
                  </td>
                  <td className="textoTablas">
                    $ {formatNumber(ListMeta.IngresosEfectivo)}
                  </td>
                  <td className="textoTablas">
                    $ {formatNumber(ListMeta.ventasCredito)}
                  </td>
                  <td className="textoTablas">
                    $ {formatNumber(ListMeta.abonosVentasCredito)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          className="col-12 col-sm-12 col-md-12 col-lg-6"
          style={{ marginTop: "100px" }}
        >
          <div className="row" style={{ justifyContent: "center" }}>
            <div className="col-12 col-sm-6 col-md-6 col-lg-6">
              <Doughnut
                data={{
                  labels: ["Total CxC", "Vencido"],
                  datasets: [
                    {
                      data: [ListMeta.TotalCxC, ListMeta.TotalVencido],
                      backgroundColor: [
                        "rgba(0, 255, 0, 1)",

                        "rgba(255, 0, 23, 1)",
                      ],
                      borderColor: [
                        "rgba(75, 192, 192, 1)",
                        "rgba(255, 99, 132, 1)",
                      ],
                      borderWidth: 1,
                    },
                  ],
                }}
                width={10}
                height={10}
              />
            </div>
          </div>
          <div className="row" style={{ marginTop: "50px" }}>
            <div
              className="col-6 col-sm-6 col-md-6 col-lg-6"
              style={{ textAlign: "center" }}
            >
              <p>Total CxC: $ {formatNumber(ListMeta.TotalCxC)}</p>
            </div>
            <div
              className="col-6 col-sm-6 col-md-6 col-lg-6"
              style={{ textAlign: "center" }}
            >
              <p>Vencido: $ {formatNumber(ListMeta.TotalVencido)}</p>
            </div>
          </div>

          <div className="table-responsive">
            <table className="table">
              <thead style={{ marginTop: "50px", textAlign: "center" }}>
                <tr>
                  <th
                    className="textoTablas"
                    scope="col"
                    style={{ fontWeight: "bold" }}
                  >
                    Sin Vencer
                  </th>
                  <th
                    className="textoTablas"
                    scope="col"
                    style={{ fontWeight: "bold" }}
                  >
                    Vencido 0 - 30 dias
                  </th>
                  <th
                    className="textoTablas"
                    scope="col"
                    style={{ fontWeight: "bold" }}
                  >
                    Vencido 31 - 60 dias
                  </th>
                  <th
                    className="textoTablas"
                    scope="col"
                    style={{ fontWeight: "bold" }}
                  >
                    Vencido 61 - 90 dias
                  </th>
                  <th
                    className="textoTablas"
                    scope="col"
                    style={{ fontWeight: "bold" }}
                  >
                    Vencido Mas 90
                  </th>
                </tr>
              </thead>
              <tbody style={{ textAlign: "center" }}>
                <tr>
                  <td scope="row" className="textoTablas">
                    $ {formatNumber(ListMeta.NoVencido)}
                  </td>
                  <td className="textoTablas">
                    ${formatNumber(ListMeta.VencidoMax30Dias)}
                  </td>
                  <td className="textoTablas">
                    ${formatNumber(ListMeta.VencidoMax60Dias)}
                  </td>
                  <td className="textoTablas">
                    ${formatNumber(ListMeta.VencidoMax90Dias)}
                  </td>
                  <td className="textoTablas">
                    ${formatNumber(ListMeta.VencidoMas90Dias)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {/*  */}
        <div style={{display:"contents"}}>
          <h3>ANGELA</h3>
          <div className="row" style={{ marginTop: "50px", display:"flex", flexWrap:"nowrap", alignItems:"center" }}>
            <div
              className="col-4 col-sm-4 col-md-4 col-lg-4"
              style={{ textAlign: "center" }}
            >
              <p>
                VENTAS: ${" "}
                {formatNumber(ListMeta.Ventas.Vendedor1.Ventas)}
              </p>
            </div>
            <div
              className="col-4 col-sm-4 col-md-4 col-lg-4"
              style={{ textAlign: "center" }}
            >
              <p>
                DEVOLUCIONES: ${" "}
                {formatNumber(ListMeta.Ventas.Vendedor1.Devoluciones)}
              </p>
            </div>
            <div
              className="col-4 col-sm-4 col-md-4 col-lg-4"
              style={{ textAlign: "center" }}
            >
              <p>TOTAL: $ {formatNumber(ListMeta.Ventas.Vendedor1.Total)}</p>
            </div>
          </div>
          <h3>KATHERINE</h3>
          <div className="row" style={{ marginTop: "50px", display:"flex", flexWrap:"nowrap", alignItems:"center" }}>
            <div
              className="col-4 col-sm-4 col-md-4 col-lg-4"
              style={{ textAlign: "center" }}
            >
              <p>
                VENTAS: ${" "}
                {formatNumber(ListMeta.Ventas.Vendedor2.Ventas)}
              </p>
            </div>
            <div
              className="col-4 col-sm-4 col-md-4 col-lg-4"
              style={{ textAlign: "center" }}
            >
              <p>
                DEVOLUCIONES: ${" "}
                {formatNumber(ListMeta.Ventas.Vendedor2.Devoluciones)}
              </p>
            </div>
            <div
              className="col-4 col-sm-4 col-md-4 col-lg-4"
              style={{ textAlign: "center" }}
            >
              <p>TOTAL: $ {formatNumber(ListMeta.Ventas.Vendedor2.Total)}</p>
            </div>
          </div>
          {/** */}
          <h3>WEB</h3>
          <div className="row" style={{ marginTop: "50px", display:"flex", flexWrap:"nowrap", alignItems:"center" }}>
            <div
              className="col-4 col-sm-4 col-md-4 col-lg-4"
              style={{ textAlign: "center" }}
            >
              <p>
                VENTAS: ${" "}
                {formatNumber(ListMeta.Ventas.VendedorWeb.Ventas)}
              </p>
            </div>
            <div
              className="col-4 col-sm-4 col-md-4 col-lg-4"
              style={{ textAlign: "center" }}
            >
              <p>
                DEVOLUCIONES: ${" "}
                {formatNumber(ListMeta.Ventas.VendedorWeb.Devoluciones)}
              </p>
            </div>
            <div
              className="col-4 col-sm-4 col-md-4 col-lg-4"
              style={{ textAlign: "center" }}
            >
              <p>TOTAL: $ {formatNumber(ListMeta.Ventas.VendedorWeb.Total)}</p>
            </div>
          </div>
          <h3>MAQUILA</h3>
          <div className="row" style={{ marginTop: "50px", display:"flex", flexWrap:"nowrap", alignItems:"center" }}>
            <div
              className="col-4 col-sm-4 col-md-4 col-lg-4"
              style={{ textAlign: "center" }}
            >
              <p>
                VENTAS: ${" "}
                {formatNumber(ListMeta.Ventas.Maquila.Ventas)}
              </p>
            </div>
            <div
              className="col-4 col-sm-4 col-md-4 col-lg-4"
              style={{ textAlign: "center" }}
            >
              <p>
                DEVOLUCIONES: ${" "}
                {formatNumber(ListMeta.Ventas.Maquila.Devoluciones)}
              </p>
            </div>
            <div
              className="col-4 col-sm-4 col-md-4 col-lg-4"
              style={{ textAlign: "center" }}
            >
              <p>TOTAL: $ {formatNumber(ListMeta.Ventas.Maquila.Total)}</p>
            </div>
          </div>
        </div>
        {/*  */}
      </div>
    </div>
  );
};

export default DetalleMeta;
