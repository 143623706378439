import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import { MdOutlineLocalOffer } from "react-icons/md";


const SidebarData = [
  {
    title: 'Indicadores',
    path: '/Admin/Indicadores', 
    icon: <FaIcons.FaChartLine />,
    cName: 'nav-text'
  },
  {
    title: 'Unidades',
    path: '/Admin/Index/MenuUnidades',
    icon: <IoIcons.IoIosPaper />,
    cName: 'nav-text'
  },
  {
    title: 'Whatsapp',
    path: '/Admin/Whatsapp',
    icon: <FaIcons.FaWhatsapp />,
    cName: 'nav-text'
  },
  {
    title: 'Catálogos',
    path: '/Admin/GeneradorDeCatalogos',
    icon: <FaIcons.FaFileDownload />,
    cName: 'nav-text'
  },
  {
    title: 'Carga De Fotos',
    path: '/Admin/Imagenes',
    icon: <FaIcons.FaFileDownload />,
    cName: 'nav-text'
  },
  {
    title: 'Descuentos',
    path: '/Admin/Descuentos',
    icon: <MdOutlineLocalOffer />,
    cName: 'nav-text'
  }
];

export default SidebarData;